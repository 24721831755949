import createCustomFieldFilterObject from 'utils/createCustomFieldFilterObject';
import createCustomFieldSelectedChoicesFilterObject from 'utils/createCustomFieldSelectedChoicesFilterObject';
import getFiltersFromUrl from './getFiltersFromUrl';

const createFilterInputObject = ({ location }) => {
  /*
    Helper function, use to create filter input object,
    to use it as candidateSearchFilterInput variable in queries.
  */
  const {
    jobPositionFilter,
    stageFilter,
    ratingFilter,
    hasCvFilter,
    q,
    customFieldsFilters,
    customFieldsSelectedChoicesFilters,
  } = getFiltersFromUrl(location);
  const cleanFilter = (filter) =>
    Array.isArray(filter) ? filter.map((o) => (o === 'None' ? null : o)) : null;

  return {
    jobPositions: jobPositionFilter,
    ratings: cleanFilter(ratingFilter),
    stages: stageFilter,
    hasCv: hasCvFilter,
    query: q,
    customFieldsFilter: createCustomFieldFilterObject(customFieldsFilters),
    customFieldsSelectedChoicesFilter:
      createCustomFieldSelectedChoicesFilterObject(
        customFieldsSelectedChoicesFilters,
      ),
  };
};

export default createFilterInputObject;
