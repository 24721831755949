import React, { useState, useCallback } from 'react';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';
import { useTranslation } from 'i18n';

import Modal from 'components/Modal';
import Form from 'components/Form';
import Field from 'components/Form/Field';
import Input from 'components/Form/Input';

import { DeleteOutlined, DragOutlined, PlusOutlined } from '@ant-design/icons';
import Button from 'components/Form/Button';
import cx from 'classnames';
import Alert from 'components/Alert';
import Select from 'components/Form/Select';
import {
  SEMANTICS_INPROCESS,
  SEMANTICS_HIRED,
  SEMANTICS_REJECTED_BY_RECRUITER,
  SEMANTICS_REJECTED_BY_CANDIDATE,
} from 'consts/semantics';

import styles from './StageListFormModal.less';

const initialStages = [
  {
    name: 'Application',
    semanticMeaning: SEMANTICS_INPROCESS,
  },
  {
    name: 'Interview',
    semanticMeaning: SEMANTICS_INPROCESS,
  },
  {
    name: 'Hired',
    semanticMeaning: SEMANTICS_HIRED,
  },
];

/**
 * Presentational component for displaying modal.
 * Whole logic is handled in parent components.
 */
function StageListFormModal({
  onSave,
  isVisible,
  onCancel,
  fieldErrors,
  clearFieldError,
  generalError,
  isSaving,
}) {
  const { t } = useTranslation();

  const [stageListName, setStageListName] = useState();
  const [stageListStages, setStageListStages] = useState(initialStages);

  const semanticsChoices = [
    {
      value: SEMANTICS_INPROCESS,
      label: t('StageListFormModal_semantic_inProcess'),
    },
    {
      value: SEMANTICS_HIRED,
      label: t('StageListFormModal_semantic_hired'),
    },
    {
      value: SEMANTICS_REJECTED_BY_RECRUITER,
      label: t('StageListFormModal_semantic_rejected_by_us'),
    },
    {
      value: SEMANTICS_REJECTED_BY_CANDIDATE,
      label: t('StageListFormModal_semantic_rejected_by_them'),
    },
  ];

  const onListNameInputChange = useCallback(
    (e) => {
      setStageListName(e.target.value);
      clearFieldError('name');
    },
    [setStageListName, clearFieldError],
  );

  const onStageNameInputChange = useCallback(
    (key, value, stageIndex) => {
      // Create a copy of the stages array
      const updatedStages = [...stageListStages];

      // Find the stage with the given id and update its name
      const targetStage = updatedStages[stageIndex];
      if (targetStage) {
        targetStage[key] = value;
      }
      // update all stages state
      setStageListStages(updatedStages);
      clearFieldError('stages');
    },
    [stageListStages, clearFieldError],
  );

  const addStage = useCallback(
    (e) => {
      const newStage = {
        name: e.target.value,
        semanticMeaning: SEMANTICS_INPROCESS,
      };
      setStageListStages((prev) => [...prev, newStage]);
    },
    [setStageListStages],
  );

  // hide delete icon to disable deleting the last stage
  const hideDeleteIcon = stageListStages.length <= 1;

  const deleteStage = useCallback(
    (index) => {
      const newStages = [...stageListStages];
      newStages.splice(index, 1);
      setStageListStages(newStages);
    },
    [stageListStages],
  );

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      onSave(stageListName, stageListStages);
    },
    [onSave, stageListName, stageListStages],
  );

  const onDragEnd = (result) => {
    if (!result.destination) return; // dropped outside the list

    const reorderedStages = Array.from(stageListStages);
    const [removed] = reorderedStages.splice(result.source.index, 1);
    reorderedStages.splice(result.destination.index, 0, removed);

    // Update the stages state
    setStageListStages(reorderedStages);
  };

  return (
    <Modal
      visible={isVisible}
      maskClosable={false}
      title={t('StageListFormModal_title')}
      onCancel={onCancel}
      onOk={onSubmit}
      okDisabled={isSaving}
      okText={t('StageListFormModal_okText')}
    >
      {generalError && <Alert type="error" showIcon message={generalError} />}
      <Alert type="info" showIcon className={styles.infoAlert}>
        {t('StageListFormModal_info')}
      </Alert>
      <Form onFinish={onSubmit}>
        <Field error={fieldErrors.name}>
          <Input
            type="text"
            label={t('StageListFormModal_stageListName')}
            value={stageListName}
            onChange={onListNameInputChange}
            placeholder={t('StageListFormModal_stageListName')}
            autoFocus
          />
        </Field>
        <div>
          {fieldErrors.stages && (
            <Alert type="error" showIcon message={fieldErrors.stages} />
          )}
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="stages">
              {(providedDrop) => (
                <div
                  ref={providedDrop.innerRef}
                  {...providedDrop.droppableProps}
                >
                  {stageListStages.map((stage, index) => (
                    <Draggable
                      key={index} // eslint-disable-line react/no-array-index-key
                      draggableId={index.toString()}
                      index={index}
                    >
                      {(providedDrag, snapshot) => (
                        <div
                          ref={providedDrag.innerRef}
                          {...providedDrag.draggableProps}
                          {...providedDrag.dragHandleProps}
                          className={cx(
                            styles.stage,
                            snapshot.isDragging && styles.dragging,
                          )}
                          data-testid={`stage-${index + 1}`}
                        >
                          <div className={styles.draggingIcon}>
                            <DragOutlined aria-hidden />
                          </div>
                          <Field className={styles.inputField}>
                            <Input
                              type="text"
                              value={stage.name}
                              onChange={(e) =>
                                onStageNameInputChange(
                                  'name',
                                  e.target.value,
                                  index,
                                )
                              }
                              className={styles.input}
                              data-testid="stage-input"
                              autoFocus
                            />
                          </Field>
                          <Field className={styles.selectField}>
                            <Select
                              value={stage.semanticMeaning}
                              onChange={(value) =>
                                onStageNameInputChange(
                                  'semanticMeaning',
                                  value,
                                  index,
                                )
                              }
                              className={styles.select}
                              data-testid={`StageListFormModal_semantic-${stage.name}`}
                              choices={semanticsChoices}
                            />
                          </Field>
                          {!hideDeleteIcon && (
                            <Button
                              size="medium"
                              iconOnly
                              icon={<DeleteOutlined aria-hidden />}
                              aria-label="delete stage"
                              data-testid="delete-stage"
                              className={styles.deleteButton}
                              onClick={() => deleteStage(index)}
                            />
                          )}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {providedDrop.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          <div className={styles.addButtonWrapper}>
            <Button
              size="small"
              icon={<PlusOutlined aria-hidden className={styles.icon} />}
              aria-label="add stage"
              data-testid="add-stage"
              className={styles.addButton}
              onClick={addStage}
            />
          </div>
        </div>
      </Form>
    </Modal>
  );
}

StageListFormModal.propTypes = {
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  fieldErrors: PropTypes.object,
  clearFieldError: PropTypes.func,
  isVisible: PropTypes.bool,
  generalError: PropTypes.string,
  isSaving: PropTypes.bool,
  stages: PropTypes.array,
  fieldType: PropTypes.string,
};

export default StageListFormModal;
