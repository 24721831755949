import React from 'react';
import { useTranslation } from 'i18n';

import useNotificationsQuery from 'hooks/useNotificationsQuery';

import selectSupportedNotifications from 'utils/selectSupportedNotifications';

import Notification from 'pages/notifications/NotificationsScreen/Notification';

import Timeline from 'components/Timeline';
import Content from 'components/Content';

import styles from './index.less';

// change order of displayed notifications
const DEFAULT_ORDERING = 'CREATED_AT_DESC';

function NotificationsScreen() {
  const { t } = useTranslation();
  const [notificationsData, loading] = useNotificationsQuery({
    DEFAULT_ORDERING,
  });
  const notificationsList = notificationsData?.viewer?.notifications?.edges;

  return (
    <Content
      title={t('NotificationsContainer_header-notifications')}
      size="small"
      theme="transparent"
      className={styles.contentWrapper}
    >
      {(notificationsList?.length === 0 ||
        // check if all notifications have unmapped activities to components. We check this to throw information:
        // "You don't have any notifications" when in the query there are only notifications whose activities
        // are not handled on FE
        selectSupportedNotifications(notificationsList)?.length === 0) && (
        <div>{t('NotificationsContainer_body-noNotificationsInformation')}</div>
      )}
      <Timeline
        className={styles.container}
        pending={loading && !notificationsList}
      >
        {notificationsList &&
          notificationsList.map(({ node }) => (
            <Notification
              key={node.id}
              activity={node.activity}
              status={node.status}
              createdAt={node.createdAt}
              createdBy={node.activity.createdBy}
              notificationPk={node.pk}
              notificationId={node.id}
              ordering={DEFAULT_ORDERING}
            />
          ))}
      </Timeline>
    </Content>
  );
}

export default NotificationsScreen;
