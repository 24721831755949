import ApplicationCreated from 'components/ActivityFeed/ApplicationCreated';
import ApplicationComment from 'components/ActivityFeed/ApplicationComment';
import InboundEmail from 'components/ActivityFeed/InboundEmail';
import OutboundEmail from 'components/ActivityFeed/OutboundEmail';
import ApplicationChangeRating from 'components/ActivityFeed/ApplicationChangeRating';
import ApplicationChangeAssign from 'components/ActivityFeed/ApplicationChangeAssign';
import ApplicationChangeStage from 'components/ActivityFeed/ApplicationChangeStage';
import BulkEmail from 'components/ActivityFeed/BulkEmail';

const MAP_TYPENAME_TO_COMPONENT = {
  ApplicationCreatedActivityNode: ApplicationCreated,
  ApplicationCommentActivityNode: ApplicationComment,
  ApplicationInboundEmailActivityNode: InboundEmail,
  ApplicationOutboundEmailActivityNode: OutboundEmail,
  ApplicationChangeRatingActivityNode: ApplicationChangeRating,
  ApplicationChangeAssignActivityNode: ApplicationChangeAssign,
  ApplicationChangeStageActivityNode: ApplicationChangeStage,
  BulkEmailActivityNode: BulkEmail,
};

export default MAP_TYPENAME_TO_COMPONENT;
