import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { memoize } from 'lodash-es';
import log from 'utils/log';

import useNotificationStatusChange from 'hooks/useNotificationStatusChange';

import ChangeStatusButton from 'pages/notifications/NotificationsScreen/Notification/ChangeStatusButton';
import DeleteNotificationButton from 'pages/notifications/NotificationsScreen/Notification/DeleteNotificationButton';

import MAP_TYPENAME_TO_COMPONENT from './notificationsMapTypnameToComponent';

const NOTIFICATION_STATUS = {
  READ: 'READ',
  UNREAD: 'UNREAD',
};

// Error for unmapped activities.
// We use memoize to prevent logging errors for the same activities.
const logErrorOnce = memoize(log);

function Notification({
  activity,
  status,
  createdAt,
  createdBy,
  notificationPk,
  notificationId,
  ordering,
}) {
  const NotificationActivity = MAP_TYPENAME_TO_COMPONENT[activity.__typename];
  const isHighlighted = status === NOTIFICATION_STATUS.UNREAD;
  const [onChangeStatus] = useNotificationStatusChange({
    notificationPk,
    notificationId,
  });

  // Set isVisible to false to hide the notification by adding class to activity with display: none.
  // When a user clicks "restore the notification", isVisible is set to true.
  const [isVisible, setIsVisible] = useState(true);

  // Throw an error for unmapped activities and return null to prevent collapsing the application
  if (!NotificationActivity) {
    logErrorOnce(`No component defined for ${activity.__typename}`);
    return null;
  }

  return (
    <NotificationActivity
      {...activity}
      createdBy={createdBy}
      createdAt={createdAt}
      isVisible={isVisible}
      isHighlighted={isHighlighted}
      // clicking the Link in Activities sets the notification as read
      onClick={() => onChangeStatus(NOTIFICATION_STATUS.READ)}
      toolbar={
        <>
          <DeleteNotificationButton
            notificationPk={notificationPk}
            ordering={ordering}
            onRestore={() => setIsVisible(true)}
            onDelete={() => setIsVisible(false)}
          />
          <ChangeStatusButton
            onChangeStatus={onChangeStatus}
            isHighlighted={isHighlighted}
          />
        </>
      }
    />
  );
}

Notification.propTypes = {
  activity: PropTypes.object,
  createdAt: PropTypes.string,
  createdBy: PropTypes.object,
  status: PropTypes.string,
  notificationPk: PropTypes.string,
  notificationId: PropTypes.string,
  ordering: PropTypes.string,
};

export default Notification;
