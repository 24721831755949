import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  EnvironmentOutlined,
  LockOutlined,
  HourglassOutlined,
  LinkOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { message } from 'antd';
import i18n, { withTranslation } from 'i18n';
import cx from 'classnames';
import copyToClipboard from 'copy-to-clipboard';
import { compose } from 'apollo';
import Rating from 'pages/candidates/Rating';
import Stage from 'pages/candidates/Stage';
import ApplicationActivityFeed from 'pages/candidates/ApplicationActivityFeed';
import DateWithIcon from 'components/DateWithIcon';
import MoreMenu from 'components/MoreMenu';
import RemoteTag from 'components/RemoteTag';
import Alert from 'components/Alert';
import ActivateOrChangePlanButton from 'components/ActivateOrChangePlanButton';
import NoPermissions from 'components/NoPermissions';
import Link from 'components/Link';

import withOrganizationPk from 'hoc/withOrganizationPk';
import withPermissions from 'hoc/withPermissions';
import withOrganizationModules from 'hoc/withOrganizationModules';

import { logAndShowGenericError } from 'utils/log';
import confirmDeleteModal from 'utils/confirmDeleteModal';
import { PERMISSION_APPLICATION_MANAGE } from 'consts/permissions';

import CustomFieldsList from './CustomFieldsValues';
import AssignedTo from './AssignedTo';
import ApplicationToolbar from './ApplicationToolbar';

import styles from './index.less';

const getMoreMenu = (t, isDisabled) => [
  {
    key: 'copyLink',
    iconComponent: LinkOutlined,
    content: t('copyLink'),
    dataRole: 'copy-application-link',
  },
  {
    key: 'delete',
    iconComponent: DeleteOutlined,
    content: t('deleteApplication'),
    disabled: isDisabled,
    dataRole: 'delete-application',
  },
];

const onPositionClick = (withLinkableHeaderNames) => (e) => {
  if (!withLinkableHeaderNames) {
    e.preventDefault();
  }
};

class ApplicationDetails extends PureComponent {
  static propTypes = {
    applications: PropTypes.array,
    activeApplicationPk: PropTypes.string,
    organizationPk: PropTypes.string.isRequired,
    candidate: PropTypes.object.isRequired,
    candidatePk: PropTypes.string.isRequired,
    deleteApplication: PropTypes.func.isRequired,
    withLinkableHeaderNames: PropTypes.bool,
    popupContainerRef: PropTypes.object,
    contactDetails: PropTypes.array.isRequired,
    onApplicationDeleted: PropTypes.func.isRequired,
    // From HoCs
    t: PropTypes.func.isRequired,
    permissions: PropTypes.array.isRequired,
  };

  onDeleteApplication = () => {
    const {
      deleteApplication,
      activeApplicationPk,
      organizationPk,
      t,
      onApplicationDeleted,
    } = this.props;

    deleteApplication(activeApplicationPk, organizationPk)
      .then(
        ({
          data: {
            deleteApplication: { errors },
          },
        }) => {
          if (!errors) {
            onApplicationDeleted();
          } else {
            message.error(t('deleteApplicationError'));
          }
        },
      )
      .catch(
        logAndShowGenericError('deleteApplication rejected', {
          props: this.props,
          state: this.state,
        }),
      );
  };

  render() {
    const {
      applications,
      activeApplicationPk,
      t,
      candidatePk,
      withLinkableHeaderNames,
      popupContainerRef,
      permissions,
      contactDetails,
      candidate,
    } = this.props;

    const currentApplication = applications.find(
      ({ node }) => node.pk === activeApplicationPk,
    );
    if (!currentApplication) return null;

    const { jobPosition, assignedTo, ratingOption, stage, pk, id } =
      currentApplication.node;

    const isJobPositionActive = jobPosition.isActive;
    const currentUrl = window.location.origin;
    const hasApplicationManagePermission = permissions.includes(
      PERMISSION_APPLICATION_MANAGE,
    );

    return (
      <div
        className={styles.applicationDetails}
        data-testid="ApplicationDetails"
      >
        <div className={styles.applicationHeader}>
          <div className={styles.applicationPosition}>
            <Link
              to={`/candidates/${candidatePk}/${pk}`}
              className={cx(!withLinkableHeaderNames && styles.notLink)}
              data-testid="application-position"
              onClick={onPositionClick(withLinkableHeaderNames)}
            >
              {jobPosition.position}
              {!isJobPositionActive && (
                <>
                  {' '}
                  <LockOutlined />
                </>
              )}
            </Link>
            <MoreMenu
              items={getMoreMenu(t, !hasApplicationManagePermission)}
              onItemClick={(key) => {
                if (key === 'delete') {
                  confirmDeleteModal(
                    t('sureWantToDeleteApplication'),
                    this.onDeleteApplication,
                  );
                } else if (key === 'copyLink') {
                  copyToClipboard(
                    `${currentUrl}/candidates/${candidatePk}/${activeApplicationPk}`,
                  );
                  message.info(i18n.t('applicationLinkCopiedToClipboard'));
                }
              }}
            />
          </div>
          <div className={styles.applicationHeaderInfo}>
            {(jobPosition.location || jobPosition.isRemote) && (
              <div className={styles.applicationLocation}>
                <EnvironmentOutlined /> {jobPosition.location}{' '}
                {jobPosition.isRemote && <RemoteTag />}
              </div>
            )}
            {jobPosition.deadlineAt && (
              <DateWithIcon
                date={jobPosition.deadlineAt}
                iconComponent={HourglassOutlined}
                text={t('ends')}
              />
            )}
          </div>
        </div>
        <div className={styles.customFieldsContainer}>
          <CustomFieldsList
            applicationPk={currentApplication.node.pk}
            candidatePk={candidatePk}
          />
        </div>
        {!isJobPositionActive && (
          <div className={styles.alertWrapper}>
            <Alert
              type="warning"
              cta={
                <ActivateOrChangePlanButton
                  jobPositionPk={jobPosition.pk}
                  initialStage="activate"
                >
                  {t('activate')}
                </ActivateOrChangePlanButton>
              }
            >
              {t('thisJobPositionIsNotActive')}
            </Alert>
          </div>
        )}
        {!hasApplicationManagePermission ? (
          <div className={styles.alertWrapper}>
            <NoPermissions />
          </div>
        ) : null}
        <div className={styles.applicationCandidateInfo}>
          <div className={styles.selectContainer}>
            <div className={styles.applicationSubsectionTitle}>
              {t('rating')}
            </div>
            <Rating
              data-testid="rating-select"
              rating={ratingOption}
              ratingScalePk={jobPosition.ratingScale.pk}
              applicationPk={activeApplicationPk}
              applicationId={id}
              candidatePk={candidatePk}
              disabled={!isJobPositionActive || !hasApplicationManagePermission}
              aria-label={t('rating')}
            />
          </div>
          <div className={styles.selectContainer}>
            <div className={styles.applicationSubsectionTitle}>
              {t('stage')}
            </div>
            <Stage
              data-testid="stage-select"
              stagePk={stage.pk}
              stageListPk={stage.stageList.pk}
              applicationPk={activeApplicationPk}
              applicationId={id}
              candidatePk={candidatePk}
              popupContainerRef={popupContainerRef}
              disabled={!isJobPositionActive || !hasApplicationManagePermission}
              aria-label={t('stage')}
            />
          </div>
          <div className={styles.selectContainer}>
            <div className={styles.applicationSubsectionTitle}>
              {t('assignedTo')}
            </div>
            <AssignedTo
              data-testid="assigned-to-select"
              value={assignedTo && assignedTo.pk}
              applicationPk={activeApplicationPk}
              applicationId={id}
              candidatePk={candidatePk}
              popupContainerRef={popupContainerRef}
              disabled={!isJobPositionActive || !hasApplicationManagePermission}
              aria-label={t('assignedTo')}
            />
          </div>
          <ApplicationToolbar
            application={currentApplication.node}
            candidate={candidate}
            contactDetails={contactDetails}
          />
        </div>
        <ApplicationActivityFeed applicationPk={activeApplicationPk} reversed />
      </div>
    );
  }
}

export default compose(
  withOrganizationPk,
  withTranslation('translation'),
  withPermissions,
  withOrganizationModules,
)(ApplicationDetails);
