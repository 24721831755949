import React from 'react';
import { useTranslation } from 'i18n';
import { Route, Routes } from 'react-router-dom';

import Content from 'components/Content';
import Page404 from 'components/Page404';
import UserSettings from './profile/UserSettings';
import EmailTemplatesSettings from './email-templates/EmailTemplatesSettings';
import CustomFieldsSettings from './custom-fields/CustomFieldsSettings';

import SettingsMenu from './SettingsMenu';
import StageListsSettings from './stage-lists/StageListsSettings';

function Settings() {
  const { t } = useTranslation();
  return (
    <Content title={t('settings')} size="small" theme="transparent">
      <Routes>
        <Route index element={<SettingsMenu />} />
        <Route path="user-settings" element={<UserSettings />} />
        <Route path="email-templates" element={<EmailTemplatesSettings />} />
        <Route path="custom-fields" element={<CustomFieldsSettings />} />
        <Route path="stage-lists" element={<StageListsSettings />} />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </Content>
  );
}

export default Settings;
