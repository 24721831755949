import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { gql } from '@apollo/client';

import useStageListForm from './useStageListForm';
import StageListFormModal from './StageListFormModal';

export const ADD_STAGE_LIST_MUTATION = gql`
  mutation AddStageListMutation(
    $organizationPk: String!
    $name: String!
    $stages: [StageInputType]!
  ) {
    addStageList(
      organizationPk: $organizationPk
      name: $name
      stages: $stages
    ) {
      stageList {
        pk
        name
        stages {
          pk
          name
          level
          semanticMeaning
        }
      }
      errors {
        message
        fields
      }
    }
  }
`;

function StageListAddModal({ isVisible, onFinish }) {
  const {
    onSave,
    setFieldErrors,
    fieldErrors,
    clearFieldError,
    generalError,
    isSaving,
  } = useStageListForm({
    onFinish,
  });

  const onAdd = useCallback(
    (stageListName, stageListStages) =>
      onSave({
        name: stageListName,
        stages: stageListStages,
      }),
    [onSave],
  );

  return (
    <StageListFormModal
      onSave={onAdd}
      isVisible={isVisible}
      onCancel={onFinish}
      fieldErrors={fieldErrors}
      setFieldErrors={setFieldErrors}
      clearFieldError={clearFieldError}
      generalError={generalError}
      isSaving={isSaving}
    />
  );
}

StageListAddModal.propTypes = {
  isVisible: PropTypes.bool,
  onFinish: PropTypes.func.isRequired,
};

export default StageListAddModal;
