import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { iconNames, MATERIAL_SYMBOL_SIZE } from 'consts/materialSymbols';
import Modal from 'components/Modal';
import { useTranslation } from 'react-i18next';
import MaterialSymbolIcon from 'components/MaterialSymbolIcon';
import Search from 'components/Search';
import styles from './index.less';

function IconPicker({ onSave, isVisible, onCancel }) {
  const { t } = useTranslation();
  const [selectedIcon, setSelectedIcon] = useState('');
  const [filterQuery, setFilterQuery] = useState('');

  const handleIconClick = useCallback(
    (iconName) => {
      setSelectedIcon(iconName);
    },
    [setSelectedIcon],
  );

  const handleSubmit = useCallback(() => {
    onSave(selectedIcon);
  }, [onSave, selectedIcon]);

  const handleFilterChange = useCallback(
    (e) => {
      setFilterQuery(e.target.value);
    },
    [setFilterQuery],
  );

  const filteredIconNames = iconNames.filter((iconName) =>
    iconName.toLowerCase().includes(filterQuery.toLowerCase()),
  );

  return (
    <Modal
      visible={isVisible}
      maskClosable={false}
      title={t('IconPickerModal_title')}
      onCancel={onCancel}
      onOk={handleSubmit}
      okText={t('IconPickerModal_okText')}
    >
      <div className={styles.pickerContainer}>
        <div className={styles.selectedIcon}>
          <div>{t('IconPickerModal_selectedIcon')}</div>
          {selectedIcon && (
            <MaterialSymbolIcon
              value={selectedIcon}
              size={MATERIAL_SYMBOL_SIZE}
            />
          )}
        </div>
        <Search
          className={styles.searchInput}
          placeholder={t('IconPickerModal_searchPlaceholder')}
          onChange={handleFilterChange}
        />
        <div className={styles.iconsWrapper}>
          {filteredIconNames.map((iconName) => (
            <button
              type="button"
              key={iconName}
              className={styles.iconButton}
              onClick={() => handleIconClick(iconName)}
            >
              <MaterialSymbolIcon
                value={iconName}
                size={MATERIAL_SYMBOL_SIZE}
              />
            </button>
          ))}
        </div>
      </div>
    </Modal>
  );
}

IconPicker.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  isVisible: PropTypes.bool,
};

export default IconPicker;
