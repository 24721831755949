import React from 'react';
import { compose, graphql } from 'apollo';
import { withTranslation } from 'i18n';
import { withRouter } from 'utils/withRouter';

import withOrganizationPk from 'hoc/withOrganizationPk';
import withCurrentUser from 'hoc/withCurrentUser';

import billingInformationQuery from 'components/ActivateOrChangePlanButton/billingInformationQuery.gql';

import ApplicationDetails from './ApplicationDetails';
import candidateWithApplicationsQuery from '../CandidateWithApplications/candidateWithApplicationsQuery.gql';
import candidatesQuery from '../CandidatesList/candidatesQuery.gql';
import deleteApplicationMutation from './deleteApplicationMutation.gql';

function ApplicationDetailsContainer(props) {
  return <ApplicationDetails {...props} />;
}

export default compose(
  withOrganizationPk,
  withCurrentUser,
  withRouter,
  withTranslation('translation'),
  graphql(deleteApplicationMutation, {
    props: ({ mutate }) => ({
      deleteApplication: (applicationPk, organizationPk) =>
        mutate({
          variables: { applicationPk, organizationPk },
          refetchQueries: [candidateWithApplicationsQuery, candidatesQuery],
        }),
    }),
  }),
  graphql(billingInformationQuery, {
    options: ({ organizationPk }) => ({ variables: { organizationPk } }),
    props: ({ data: { viewer, loading } }) => ({
      billing: !loading && viewer ? viewer.organizations[0].billing : {},
    }),
  }),
)(ApplicationDetailsContainer);
