import React, { useState, useCallback, memo, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  LockOutlined,
  PlusCircleOutlined,
  UserAddOutlined,
} from '@ant-design/icons';
import { Tabs } from 'antd';
import { useTranslation } from 'i18n';

import DateWithIcon from 'components/DateWithIcon';
import Link from 'components/Link';
import Button from 'components/Form/Button';
import Rating from 'pages/candidates/Rating';
import Stage from 'pages/candidates/Stage';
import AddApplicationContainer from 'pages/candidates/AddApplication';
import useBrowserWidth from 'hooks/useBrowserWidth';
import usePermissions from 'hooks/usePermissions';
import { MD } from 'consts/media';
import { PERMISSION_CANDIDATE_DETAILS_MANAGE } from 'consts/permissions';

import { CurrentCandidateContext } from 'providers/CurrentCandidateProvider';
import styles from './index.less';

const TabPane = Tabs.TabPane;

const onApplicationClick = (e) => {
  if (!e.metaKey) {
    e.preventDefault();
  }
};

const renderApplicationTab = (node, browserWidth, candidatePk) => (
  <Link
    className={styles.tabContainer}
    onClick={onApplicationClick}
    to={`/candidates/${candidatePk}/${node.pk}`}
  >
    <b className={styles.tabName}>
      {node.jobPosition.position}
      {!node.jobPosition.isActive && (
        <>
          {' '}
          <LockOutlined />
        </>
      )}
    </b>
    {browserWidth > MD && (
      <div className={styles.tabInfo}>
        <Rating
          rating={node.ratingOption}
          ratingScalePk={node.jobPosition.ratingScale.pk}
          className={styles.tabRating}
          nonChangeable
        />
        <Stage
          stagePk={node.stage.pk}
          stageListPk={node.stage.stageList.pk}
          className={styles.tabStage}
          nonChangeable
        />
        <DateWithIcon
          date={node.createdAt}
          iconComponent={UserAddOutlined}
          className={styles.tabDate}
        />
      </div>
    )}
  </Link>
);

const renderRestrictedApplicationTab = (node, browserWidth) => (
  <div className={styles.restrictedTabContainer}>
    <b className={styles.tabName}>{node.jobPositionName}</b>
    {browserWidth > MD && (
      <div className={styles.tabInfo}>
        <DateWithIcon
          date={node.createdAt}
          icon="user-add"
          className={styles.tabDate}
        />
      </div>
    )}
  </div>
);

function CandidateApplicationsList({
  candidatePk,
  applications,
  activeApplicationPk,
  popupContainerRef,
  onApplicationSelect,
}) {
  const restrictedApplications =
    useContext(CurrentCandidateContext)?.restrictedApplications?.edges || [];

  const browserWidth = useBrowserWidth();
  const { t } = useTranslation();
  const [hasCandidateDetailsManagePermissions, { showNoPermissionMessage }] =
    usePermissions([PERMISSION_CANDIDATE_DETAILS_MANAGE]);
  const [isAddApplicationFormVisible, setIsAddApplicationFormVisible] =
    useState(false);
  const toggleApplicationSelectVisibility = useCallback(() => {
    setIsAddApplicationFormVisible(!isAddApplicationFormVisible);
  }, [isAddApplicationFormVisible]);

  return (
    <div className={styles.applications}>
      <div className={styles.sectionTitle}>
        <span className={styles.sectionTitleText}>{t('jobPositions')}</span>
        {!isAddApplicationFormVisible && (
          <Button
            iconOnly
            icon={
              <PlusCircleOutlined
                className={styles.addButton}
                data-testid="add-application"
                aria-hidden
              />
            }
            onClick={
              hasCandidateDetailsManagePermissions
                ? toggleApplicationSelectVisibility
                : showNoPermissionMessage
            }
            aria-label="add-application"
          />
        )}
      </div>
      {isAddApplicationFormVisible && (
        <AddApplicationContainer
          candidatePk={candidatePk}
          toggleApplicationSelectVisibility={toggleApplicationSelectVisibility}
          popupContainerRef={popupContainerRef}
          onApplicationSelect={onApplicationSelect}
        />
      )}
      <Tabs
        tabPosition={browserWidth > MD ? 'left' : 'top'}
        activeKey={activeApplicationPk}
        onTabClick={onApplicationSelect}
        className={styles.tabs}
      >
        {applications &&
          applications.map(({ node }) => (
            <TabPane
              tab={renderApplicationTab(node, browserWidth, candidatePk)}
              key={node.pk}
              className={styles.tabPane}
            />
          ))}
      </Tabs>
      <Tabs
        tabPosition={browserWidth > MD ? 'left' : 'top'}
        activeKey={activeApplicationPk}
        className={styles.tabs}
      >
        {restrictedApplications.map(({ node }) => (
          <TabPane
            tab={renderRestrictedApplicationTab(node, browserWidth)}
            key={node.pk}
            className={styles.tabPane}
          />
        ))}
      </Tabs>
    </div>
  );
}

CandidateApplicationsList.propTypes = {
  candidatePk: PropTypes.string.isRequired,
  applications: PropTypes.array,
  activeApplicationPk: PropTypes.string,
  popupContainerRef: PropTypes.object,
  onApplicationSelect: PropTypes.func,
};

export default memo(CandidateApplicationsList);
